import React, { Component } from 'react';

class Form extends Component {
  render() {
    return (
      <div className='column'>
        <form className='poster-details'>
          <div className="layout-form">
            <h3>Layout</h3>
            <div id="LayoutButtons">
              <button type="button" id="MPS" onClick={this.props.handleLayoutChange}>
                <div>MAP</div>
                <div>PICTURE</div>
                <div>STATISTICS</div>
              </button>
              <button type="button" id="PS" onClick={this.props.handleLayoutChange}>
                <div>PICTURE</div>
                <div>STATISTICS</div>
              </button>
              <button type="button" id="MS" onClick={this.props.handleLayoutChange}>
                <div>MAP</div>
                <div>STATISTICS</div>
              </button>
            </div>
          </div>
          <div className="theme-form">
            <h3>Theme</h3>
            <div id="ThemeButtons">
              <button type="button" id="light-v10" onClick={this.props.handleThemeChange}>LIGHT</button>
              <button type="button" id="dark-v10" onClick={this.props.handleThemeChange}>DARK</button>
              <button type="button" id="outdoors-v11" onClick={this.props.handleThemeChange}>OUTDOORS</button>
              <button type="button" id="satellite-v9" onClick={this.props.handleThemeChange}>SATELLITE</button>
            </div>
          </div>
          <div className="theme-form">
            <h3>Format</h3>
            <div id="ThemeButtons">
              <button id='A4' type="button" onClick={this.props.handleFormatChange}>Poster A4</button>
              <button id='mug' type="button" onClick={this.props.handleFormatChange}>Mug</button>
            </div>
          </div>
          <div className="theme-form">
            <h3>Price {this.props.price ? this.props.price + '€' : ''}</h3>
          </div>
          {this.props.activity && <div className="data-form">
            <h3>Data</h3>
            <div className="upload-image-div">
              <input className="upload-image" type="file"  onChange={this.props.handleUploadPicture}/>
            </div>
            <div style={{display:'flex'}} className="form-row">
              <input value={this.props.allValues.value1} onChange={this.props.handleValueChange} name="value1" type="text"></input>
              <input value={this.props.allValues.label1} type="text" onChange={this.props.handleValueChange} name="label1"></input>
            </div>
            <div style={{display:'flex'}} className="form-row">
              <input value={this.props.allValues.value2} onChange={this.props.handleValueChange} name="value2" type="text"></input>
              <input value={this.props.allValues.label2} type="text" onChange={this.props.handleValueChange} name="label2"></input>
            </div>
            <div style={{display:'flex'}} className="form-row">
              <input value={this.props.allValues.value3} onChange={this.props.handleValueChange} type="text" name="value3"></input>
              <input value={this.props.allValues.label3}  type="text" onChange={this.props.handleValueChange} name="label3"></input>
            </div>
            <div style={{display:'flex'}} className="form-row">
              <input value={this.props.allValues.value4} onChange={this.props.handleValueChange} type="text" name="value4"></input>
              <input value={this.props.allValues.label4} type="text" onChange={this.props.handleValueChange} name="label4"></input>
            </div>
            <input
            type='text' className="input-title" value={this.props.allValues.name} name='title' placeholder='Title' onChange={this.props.handleValueChange} />
          </div>}
        </form>
          <button className="button button-send-to-cart" type="button" onClick={this.props.handleAddToCart}>Add to Cart</button>

      </div>
    );
  }
}

export default Form;
