import React from "react";
import TopBar from './TopBar';
import NavBar from './NavBar';

const Policy = () => {

    return (
        <div>
            <TopBar />
            <div className="wrapper">

                <NavBar />

                <div className="opinion-description"><h3>Terms and Conditions</h3></div>
                <div className="opinionscolumn"></div>

                <div className="policy">
                    <p>The following terms and conditions govern all use of the “thewinmemo.com website”. The Service is owned and operated by MJ Online LLC, a company incorporated in Ireland, “The Win Memo” prints customized maps directly to customers who track their sports activities through apps, from which users can retrieve data to share with The Win Memo. If you do not agree to this Agreement, do not use the service.</p>
                    <h4>Payment and fees</h4>
                    <p>When you order a product (print) you will be charged then current fees, which we may change from time to time (such when we have holiday sales or offer you a discount of base product price). We may choose to temporarily change the fees for our Products. <br/>
                        By placing an order through Thewinmemo.com, you are confirming that you are legally entitled to use the means of payment tendered and, in the case of card payments, that you are either the cardholder or have the cardholder’s express permission to utilize the card to effect payment.<br/>
                        Unless otherwise stated, all fees and payments are quoted in Euros (). The User is responsible for paying all fees, payments and applicable taxes associated with our Site and Products. After receiving your order you will receive an e-mail from us confirming the details, description, and price for the prints ordered together with some information on your rights to return your goods.
                    </p>
                    <h4>Customs</h4>
                    <p>Customers outside of the European Union may be subjected to pay custom duties (as applicable within each jurisdiction). The Win Memo will not be held responsible for any such duties.</p>
                    <h4>Shipping & Returns</h4>
                    <p>Once you have clicked on the “Buy” button, it is not possible to edit or cancel your order. Due to the individualization of the prints there is no right of withdrawal for the prints after placing the order.</p>
                    <h4>Creation of prints</h4>
                    <p>If you choose to upload a product for print, make sure that you are the owner of the rights to print the image. By uploading an image you provide “The Win Memo” with the right to store the image and to print it.</p>
                    <h4>Attribution and copyright to work of others</h4>
                    <p>The Win Memo uses  <a href="https://www.mapbox.com/about/maps/">© Mapbox</a>, to power the mapping and you can <a href="https://apps.mapbox.com/feedback/#/-74.5/40/10">improve this map.</a></p>
                </div>

            </div>
            </div>
    )
}

export default Policy;