import React, { Component } from "react";
import TopBar from '../components/TopBar';
import NavBar from '../components/NavBar';

class Home extends Component {

    connectToStrava = async () => {
        await fetch(`${process.env.REACT_APP_PROD_URL}/strava`);
      }

    render() {
        return (
            <div className="Home">
                <TopBar/>
                <NavBar/>
                <div className="home-banner">
                    <div className="wrapper">
                        <h1>Your triumph.</h1>
                        <h1>Your reward.</h1>
                        <div className="how-to">
                            <h3>Create your trophy by following three simple steps:</h3>
                            <p><b>1. </b>Connect to your Strava account and choose your best workout <br></br>
                            <b>2. </b>Use our editior to choose the format and customize your trophy <br></br>
                            <b>3. </b>Complete the order and receive your trophy at your doorstep</p>
                        </div>
                        <div className="create-poster-home">
                            <a href="https://site--thewinmemo--the-win-memo--thew-cncx.code.run/strava">Create yours now</a> 
                        </div>
                    </div>
                </div>
                {/* <div className="how-to">
                    <h3>Create your trophy by following three simple steps:</h3>
                    <p><b>1. </b>Connect to your Strava account and choose the activity you want a trophy for</p>
                    <p><b>2. </b>Use our editior to choose the format and customize your trophy</p>
                    <p><b>3. </b>Complete the order and receive your trophy at your doorstep</p>
                </div> */}
                <div className="posters-home">
                        <div className="posters">
                            <div>
                                <img src="images/poster1.png" alt="poster"/>
                            </div>
                            <div>
                                <img src="images/poster2.png" alt="poster"/>
                            </div>
                            <div>
                                <img src="images/poster3.png" alt="poster"/>
                            </div>
                        </div>
                    </div>
                

                <div className="footer">
                     <p className="footer-left-side">Copyright © 2021 MJonline (695014). All rights reserved.  
                         <a href="/policy">Terms and conditions</a>
                     </p>
                </div>
                </div>
        );
    }
}

export default Home;