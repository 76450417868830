import React, { Component } from "react";
import Import from './Import.js';
import TopBar from '../components/TopBar';
import NavBar from '../components/NavBar';
import Axios from 'axios';
import {SERVER_URL} from '../config';

class Profile extends Component {

    userId = new String;
    token = new String;

    state = {
        response: '',
        post: '',
        responseToPost: ''
    };

    componentDidMount() {
        this.callApi()
            .then(res => {
                this.setState({ response: res })
            })
            .catch(err => console.log(err.message));
    }

    callApi = async () => {
        try {
            const response = await Axios.get(`${SERVER_URL}/api/hello`);
            this.token = response.data.token;
            return response.data;
        } catch (err){
            throw Error(err.message)
        }

    };

    render() {
        return (
            <div className="Profile">
                <TopBar/>
                <NavBar/>
                <div className="profile-banner">
                    <div className="wrapper">
                        <h1 className="profile-title">Activities</h1>
                    </div>
                </div>
                <div className="activities">
                    <div className="wrapper">
                        <Import token={this.token} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;
