import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Profile from './components/Profile';
import Home from './components/Home';
import Editor from './components/Editor';
import Cart from './components/Cart';
import Opinions from './components/Opinions';
import Faq from './components/Faq';
import Products from './components/Products';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./components/CheckoutForm";
import Policy from "./components/Policy";
const stripePromise = loadStripe('pk_test_51IrJIcGaqjuDuisADAk5MflV7bVqMov90kK09DGQrBWODseelFyCQtuzkCsZnCkJ6qti6Emi81UljIMJeVDitzA300N3MvSeZP');

function App() {
  return (

    <div className="App">
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/"><Home /></Route>
            <Route exact path='/profile'><Profile /></Route>
            <Route exact path='/editor'><Editor /></Route>
            <Route exact path='/cart'><Cart /></Route>
            <Route exact path='/opinions'><Opinions /></Route>
            <Route exact path='/faq'><Faq /></Route>
            <Route exact path='/payment'><CheckoutForm /></Route>
            <Route exact path='/products'><Products /></Route>
            <Route exact path='/policy'><Policy /></Route>
          </Switch>
        </BrowserRouter>
      </Elements>
    </div>

  );
}

export default App;
