import React, { useState, useEffect } from "react";
import './Import.css';
import { useHistory } from 'react-router-dom';

function Import({ token }) {
  const [isLoading, setIsLoading] = useState(true)
  const [activities, setActivities] = useState([])
  const [user, setUser] = useState({});

  let history = useHistory();

  // strava credentials
  let clientID = process.env.REACT_APP_STRAVA_CLIENT_ID;
  let clientSecret = process.env.REACT_APP_STRAVA_CLIENT_SECRET;
  let refreshToken = token.toString();

  const callRefresh = `https://www.strava.com/oauth/token?client_id=${clientID}&client_secret=${clientSecret}&refresh_token=${refreshToken}&grant_type=refresh_token`
  const callActivities = `https://www.strava.com/api/v3/athlete/activities?access_token=`
  const callUser = `https://www.strava.com/api/v3/athlete?access_token=`

  // Use refresh token to get current access token
  useEffect(() => {
    if (refreshToken) {
      fetch(callRefresh, {
        method: 'POST'
      })
        .then(res => res.json())
        // .then(result => getActivities(result.access_token))
        .then(result => getUser(result.access_token))
    }
    if (refreshToken) {
      fetch(callRefresh, {
        method: 'POST'
      })
        .then(res => res.json())
        // .then(result => getActivities(result.access_token))
        .then(result => getActivities(result.access_token))
    }
  }, [callRefresh])


  // use current access token to call all activities
  function getActivities(access) {
    fetch(callActivities + access)
      .then(res => res.json())
      .then(data => setActivities(data), setIsLoading(prev => !prev))
      .catch(e => console.log(e))
  }

  function getUser(access){
    fetch(callUser + access)
    .then(res => res.json())
    .then(data => setUser(data))
    .catch(e => console.log(e))
  }

  function handleClick(activity, index) {    
     history.push({
      pathname: '/editor',
      state: {activity: activity}  
  })
  }

  return (
    <div className="Import">
      <div className="user-info">
        <div>
          <img src={user.profile} alt="user-image"/>
        </div>
        <div>
          <h2>Hi {user.firstname ? user.firstname : "User"}</h2>
          <h3>Choose the activity you’d like to create a mug or poster for:</h3>
        </div>

      </div>
      <div className="user-activities">
        <div className="activities">
          {activities && activities.map((activity, index) => {
            let date = new Date(activity.start_date);
            return (
              <div className="activity" key={index}>
                <div className="details">
                  <p>
                    <b className="activity-title">{activity.name}</b><br />
                    <div className="activity-info">Distance: {(activity.distance / 1000).toFixed(2)} Km </div>
                    <div className="activity-info" style={{marginBottom:'5px'}}>Elevation gain: {activity.total_elevation_gain} Metres </div>
                    <div className="activity-info">Date: {date.getDate()} - {date.getMonth() + 1} - {date.getFullYear()}</div>
                  </p>
                </div>
                <button className="button" onClick={() => handleClick(activity, index)}>Select</button>

              </div>
            );
          })}
        </div>
        
      </div>
    </div>
  );
}

export default Import;
