import React, { useState, useEffect, Component } from "react";
import { useLocation } from "react-router-dom";
import Poster from "./Poster.js";
import Form from "./Form.js";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import TopBar from "../components/TopBar";
import NavBar from "../components/NavBar";
import Cookies from "universal-cookie";

const Editor = (props) => {
  const location = useLocation();
  const [activity, setActivity] = useState(location.state ? location.state.activity : null);
  const [theme, setTheme] = useState("streets-v11");
  const [picture, setPicture] = useState(null);
  const [layout, setLayout] = useState("MPS");
  const [format, setFormat] = useState("");
  const [price, setPrice] = useState(undefined);

  const [allValues, setAllValues] = useState({
    value1: activity ? (activity.distance / 1000).toFixed() + " km" : "",
    label1: "Distance",
    value2: activity ? activity.total_elevation_gain + " m" : "",
    label2: "Elevation Gain",
    value3: activity ? (new Date(activity.moving_time * 1000).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0]) : "",
    label3: "Moving Time",
    value4: activity ? activity.average_speed.toFixed(1) + " km/h" : "",
    label4: "Average Speed",
    name: activity ? activity.name : "",
  });

  const cookies = new Cookies();

  useEffect(() => {
    let posterFormat = cookies.get("poster");
    if (posterFormat === "A4") setPrice(25);
    else if (posterFormat === "mug") setPrice(30);
  }, []);

  const handleValueChange = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const handleThemeChange = (e) => {
    cookies.set("theme", e.target.id.split("-")[0], { path: "/" });
    setTheme(e.target.id);
  };

  const handleFormatChange = (e) => {
    cookies.set("poster", e.target.id, { path: "/" });
    if (e.target.id === "A4") setPrice(25);
    else if (e.target.id === "mug") setPrice(30);
    setFormat(e.target.id);
  };

  const handleUploadPicture = (event) => {
    var selectedFile = event.target.files[0];
    var reader = new FileReader();

    var imgtag = document.getElementById("ImagePoster");
    imgtag.title = selectedFile.name;

    reader.onload = function (event) {
      imgtag.src = event.target.result;
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleLayoutChange = (event) => {
    setLayout(event.target.id);
    if(event.target.id == "MPS") {
      document.getElementById("EditorPoster").style = "max-height:830px;";
    }
    else if(event.target.id == "PS") {
      document.getElementById("EditorPoster").style = "max-height:830px;";
    }
    else if(event.target.id == "MS") {
      document.getElementById("EditorPoster").style = "max-height:750px;";
    }
  };

  const handleAddToCart = () => {
    if (!price) {
      alert("Please choose a format");
      return;
    }
    if (!cookies.get("theme")) {
      cookies.set("theme", theme.split("-")[0], { path: "/" });
    }
    window.devicePixelRatio = 3;
    const input = document.getElementById("divToPrint");

    html2canvas(input, { 
      scrollY: -window.scrollY,
      allowTaint: true,
      logging: true,
      letterRendering: 1,
      useCORS: true,
      dpi: 600,
      scale: 2
    })
    .then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 10, 10);
      window.localStorage.setItem("poster", imgData);
      setTimeout(() => {
        window.location.href = "/cart";
      }, 250);
    });
  };

  return (
    <div className="editor">
      <TopBar />
      <NavBar />
      <div className="editor-main">
        <div className="wrapper">
          <div className="editor-container">
            <div className="editor-poster" id="EditorPoster">
              <Poster activity={activity} theme={theme} picture={picture} layout={layout} allValues={allValues}></Poster>
            </div>
            <div className="editor-form">
              <Form
                activity={activity}
                handleValueChange={handleValueChange}
                handleThemeChange={handleThemeChange}
                handleFormatChange={handleFormatChange}
                handleUploadPicture={handleUploadPicture}
                handleLayoutChange={handleLayoutChange}
                handleAddToCart={handleAddToCart}
                price={price}
                allValues={allValues}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <p className="footer-left-side">Copyright © 2021 MJonline (695014). All rights reserved.  
          <a href="/policy">Terms and conditions</a>
        </p>
      </div>

    </div>
  );
};

export default Editor;
