import React from "react";
import TopBar from '../components/TopBar';
import NavBar from '../components/NavBar';
import './opinions.css';


const Opinions = () => {

    return (
        <div>
            <TopBar/>
            <div className="wrapper">
                  
            <NavBar/>
            <div className="opinion-description"><h2>Reviews and reactions of our printed posters and mugs</h2></div>
                
                <div className="opinionscolumn" style={{flex:"center"}}>
                    <div className="card">   
                        <h3>Great gift idea! </h3>
                        <b4>&#11088; &#11088; &#11088; &#11088; &#11088; <br /> I gifted the mug to my brother, who completed his first marathon. Delivery was fast, quality of the mug is perfect and my bro uses it every day for breakfast!<br/>  </b4>
                        <b4 className="author"><em>- Johan Rydberg, Switzerland </em></b4>
                    </div>
                    <div className="card">
                          <img className="imgopinion" src="images/review-spain.jpg" alt="Mariastein" width="280px" height="380px"></img>
                          <h3>Source of inspiration &#128513; </h3>
                          <b4>Biking is my newest hobby and I ordered a poster of my first 50km ride. It looks great on my work desk and it inspires me to go biking more often!<br/> </b4>
                          <b4 className="author"><em>- Amparo Garcia, Spain </em></b4>
                    </div>
                    <div className="card">
                          <h3>Amazing </h3>
                          <b4>&#11088; &#11088; &#11088; &#11088; &#11088; <br/>  Amazing! My second mug order from The Win Memo. I am creating a collection of my sport achievements. Fast shipping and great quality! <br/> </b4>
                          <b4 className="author"><em>- David Alonso, Spain </em></b4>
                    </div>
                    {/* <div className="card">
                          <img src=""></img>
                          <h3>Great gift idea! </h3>
                          <b4>Maria biked and really liked the roadMaria biked and really liked the roadMaria biked and really liked the roadMaria biked and really liked the roadMaria biked and really liked the roadMaria biked and really liked the road</b4>
                    </div> */}
            </div>
         </div>

            <div className="footer">
                  <p className="footer-left-side">Copyright © 2021 MJonline (695014). All rights reserved.  
                        <a href="/policy">Terms and conditions</a>
                  </p>
            </div>

         </div>
    );
}

export default Opinions;