import React, { Component } from "react";
import TopBar from "../components/TopBar";
import NavBar from "../components/NavBar";
import html2canvas from "html2canvas";
import Axios from "axios";
import doMToImage from "dom-to-image";
import { SERVER_URL } from "../config";
import Cookies from "universal-cookie";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      postCode: "",
      email: "",
      phone: "",
      pdfFile: "",
      showAltText: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({
      ...this.state,
      [name]: value,
    }));
  };

  handleCart = () => {
    if (
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.address1 === "" ||
      this.state.address2 === "" ||
      this.state.city === "" ||
      this.state.postCode === "" ||
      this.state.email === "" ||
      this.state.phone === ""
    ) {
      alert("All fields must be filled.");
      return;
    } else {
      window.devicePixelRatio = 3;
      document.getElementById("Poster").classList.add("big-poster");
      document.getElementById("CartForm").classList.add("big-form");
      const input = document.getElementById("PosterImage");
      
      //comment out this html2canvas
      // html2canvas(input, {
      //   allowTaint: true,
      //   dpi: 300,
      //   scale: 2,
      //   logging: true,
      //   letterRendering: 1,
      //   useCORS: true,
      // });
      // input.crossOrigin = 'anonymous';
      const options = {
        quality: 0.95,
      };
      doMToImage.toJpeg(input, options)
      .then(async (dataUrl) => {
        const formData = new FormData();
        const cookie = new Cookies();
        const format = cookie.get("poster");
        formData.append("pdf", dataUrl);
        let form = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          address1: this.state.address1,
          address2: this.state.address2,
          city: this.state.city,
          postCode: this.state.postCode,
          email: this.state.email,
          phone: this.state.phone,
        };
        Axios.post(`${SERVER_URL}/upload-file?form=${JSON.stringify(form)}&format=${format}`, formData).then((res) => {
          cookie.set("eTag", res.data.eTag, { path: "/" });

          setTimeout(() => {
            window.top.location.href = "/payment";
          }, 250);
       });
     });

    }
  };

  componentDidMount() {
    const poster = localStorage.getItem("poster");
    document.getElementById("PosterImage").src = poster;
    if (!localStorage.getItem("poster")) {
      this.setState({ showAltText: true });
    }
  }
  render() {
    return (
      <div className="Cart">
        <TopBar />
        <NavBar />
        <div className="wrapper">
          <h1 style={{ fontSize: "40px" }}>Cart</h1>
          <div className="cart-wrapper">
            <div id="Poster">
              <h2>Product preview</h2>
              <img alt="" id="PosterImage" />
              {this.state.showAltText && <p>You still have not created a poster</p>}
            </div>
            <form className="cart-form" id="CartForm">
              <h2>Enter your data</h2>
              <div className="form-row">
                <div className="field">
                  <input type="text" name="firstName" value={this.state.firstName} placeholder="Enter your first name" onChange={this.handleChange} />
                </div>
                <div className="field">
                  <input type="text" name="lastName" value={this.state.lastName} placeholder="Enter your last name" onChange={this.handleChange} />
                </div>
                <div className="field">
                  <input type="text" name="address1" value={this.state.address1} placeholder="Enter address 1" onChange={this.handleChange} />
                </div>
                <div className="field">
                  <input type="text" name="address2" value={this.state.address2} placeholder="Enter address 2" onChange={this.handleChange} />
                </div>
                <div className="field">
                  <input type="text" name="city" value={this.state.city} placeholder="Enter city" onChange={this.handleChange} />
                </div>
                <div className="field">
                  <input type="text" name="postCode" value={this.state.postCode} placeholder="Enter Postcode" onChange={this.handleChange} />
                </div>
                <div className="field">
                  <input type="text" name="email" value={this.state.email} placeholder="Enter Email" onChange={this.handleChange} />
                </div>
                <div className="field">
                  <input type="text" name="phone" value={this.state.phone} placeholder="Enter Phone" onChange={this.handleChange} />
                </div>
                {/* <button className="button button-add-to-cart" type="button" onClick={this.handleCart}>
                  Finished
                </button> */}

                {/* <a style={{cursor: pointer}}> 
                  <button className="button button-add-to-cart" type="button" onClick={this.handleCart}>Finished</button>
                </a> */}

                <a className="button-add-to-cart" onClick={this.handleCart} style={{cursor:'pointer'}}>Finished</a>


              </div>
            </form>
          </div>
        </div>

        <div className="footer">
          <p className="footer-left-side">Copyright © 2021 MJonline (695014). All rights reserved.  
            <a href="/policy">Terms and conditions</a>
          </p>
        </div>

      </div>
    );
  }
}

export default Cart;
