import React from "react";
import TopBar from '../components/TopBar';
import NavBar from '../components/NavBar';
import "./faq.css";

const Faq = () => {

    return (
        <div>
            <TopBar/>
            <NavBar/>
            <div className="wrapper">
                <h2 className="faq-title">Common questions from our customers</h2>
            <div>
                <p className="question"><b> What is The Win Memo?</b></p> 
                <p className="answers">The Win Memo is a series of unique and completely personalizable trophies for your sport achievements.</p>
            </div>
            <div>
                <p className="question"><b> How does it work? </b></p> 
                <p className="answers"><b>1.</b> Connect to your Strava account from our site <br></br><b>2.</b> Select the activity you are most proud of <br></br><b>3.</b> Use our editor to personalize your poster <br></br><b>4.</b> Fill in the form with your details and receive your poster home!</p>
            </div>
            <div>
                <p className="question"><b> Do you ship to my country and what are the shipping costs?</b></p> 
                <p className="answers">We offer free shipping on all orders coming from Europe and North America: 5-8 working days. </p>
            </div>  
            <div>
                <p className="question"><b> Who's behind The Win Memo? </b></p> 
                <p className="answers">I am Maria Alonso, a sporty 21 year old who loves running, biking and skiing.<br></br> Find out more about me here <a href="https://www.instagram.com/mariaalonso2705/">@mariaalonso2705</a></p>
            </div> 
            <div>
                <p className="question"><b> Can I return my poster? </b></p> 
                <p className="answers">As all our posters are custom made, we unfourtunately can't accept returns or offer refunds unless your product arrived damaged. <br/> </p>
            </div> 
            </div>

            <div className="footer">
                <p className="footer-left-side">Copyright © 2021 MJonline (695014). All rights reserved.  
                    <a href="/policy">Terms and conditions</a>
                </p>
            </div>

         </div>
    );
}

export default Faq;