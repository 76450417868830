import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

const Map = (props) => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    // eslint-disable-next-line import/no-webpack-loader-syntax
    mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
    
    useEffect(() => {

        var map = new mapboxgl.Map({
            container: 'mapContainer',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [-74.5, 40],
            zoom: 10,
            preserveDrawingBuffer: true
        });

        if(props.activity && props.activity.map.summary_polyline  !== null){

        var lat = parseFloat(props.activity.start_latitude);
        var lng = parseFloat(props.activity.start_longitude);
        map.setCenter([lng,lat]);

        var polyline = require('@mapbox/polyline');
        var polyLineDecoded = polyline.decode(props.activity.map.summary_polyline);

        polyLineDecoded.map((item) => {
            let temp = item[1];
            item[1] = item[0];
            item[0] = temp
        })

         map.on('load', function () {
            addMaineLayer();
            map.resize();

            let themeButtons = document.getElementById("ThemeButtons");
            let buttons = themeButtons.getElementsByTagName('button');

            function switchLayer(layer) {
                map.once("styledata", addMaineLayer);
                var layerId = layer.target.id;
                map.setStyle('mapbox://styles/mapbox/' + layerId);
            }

            for (var i = 0; i < buttons.length; i++) {
                buttons[i].onclick = switchLayer;
            }
         })
         

         function addMaineLayer() {
            map.addSource('route', {
                    'type': 'geojson',
                    'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                    'type': 'LineString',
                    // 'coordinates': polyLineDecoded
                    coordinates:
                        polyLineDecoded
                    }
                    }
                    });
                    const addLayer = (layer, beforeId) => map.addLayer(layer, beforeId);
                    addLayerBefore(
                        addLayer,
                        {
                            'id': 'route',
                            'type': 'line',
                            'source': 'route',
                            'layout': {
                            'line-join': 'round',
                            'line-cap': 'round'
                            },
                            'paint': {
                            'line-color': '#fc4c02',
                            'line-width': 3
                            }
                        }, 'road-oneway-arrow-blue'
                      );
         }

         function addLayerBefore(addLayerFn, layer, beforeId) {
            const beforeLayer = Boolean(beforeId) && map.getLayer(beforeId);
            if (beforeLayer && beforeId === beforeLayer.id) addLayerFn(layer, beforeId);
            else {
              console.warn(
                `Not found layer with id '${beforeId}'.\nLayer '${
                  layer.id
                }' added without before.`
              );
              addLayerFn(layer);
            }
          }

        }

    }, []);

    return (
        <div>
            <div id="mapContainer" className={props.layout === "PS" ? 'Map display-none' : 'Map display-block'}></div>
        </div>
    );
};

export default Map;
