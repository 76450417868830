import React from "react";

const TopBar = () => {
    

    return (
        <div className="top-bar">
            <div>Celebrate your latest achievement with a poster or mug | Free shipping in Europe and North America</div>
         </div>
    );
}

export default TopBar;