import React, { useState} from "react";
import { SERVER_URL } from "../config";

const NavBar = () => {

    const [openMenu, setOpenMenu] = useState(false);
    
    const handleViewMenu = () => {
        setOpenMenu(!openMenu);
    }

    return (
        <div className="wrapper">
            <div className="nav-bar">
                <div className="nav-bar-left-side">
                    <a href="/">
                        <img src="images/logo.png" alt="TheWinMemo"/>
                    </a>
                    <nav>
                        <ul>
                            <li><a href="/products">PRODUCTS</a></li>
                            <li><a href="/opinions">REVIEWS</a></li>
                            <li><a href="/faq">FAQ</a></li>
                            <li><a href="/cart">CART</a></li>
                        </ul>
                    </nav>
                </div>
                <div className="create-poster">
                    <a href={`${SERVER_URL}/strava`}>Create yours now </a>
                </div>
                <button type="button" onClick={handleViewMenu} className="hamburger" id="Hamburger">
                    <i className="fas fa-bars"></i>
                </button>
            </div>
            <div className={openMenu ? "menu-mobile view-mobile" : "menu-mobile"}>
                <ul>
                    <li><a href="/products">PRODUCTS</a></li>
                    <li><a href="/opinions">REVIEWS</a></li>
                    <li><a href="/faq">FAQ</a></li>
                    <li><a href="/cart">CART</a></li>
                    <li><a href={`${SERVER_URL}/strava`}>Create your poster</a></li>
                </ul>
            </div>
         </div>
    );
}

export default NavBar;
