import React from "react";
import TopBar from '../components/TopBar';
import NavBar from '../components/NavBar';
import "./products.css";

const Products = () => {

    return (
        <div>
            <TopBar />
            <NavBar />

            <div className="product-intro">
                <h2>PRODUCTS</h2>
                <p>Buy a beatiful mug or poster for your sport achievement. <br/> </p>
                <p>Free shipping in Europe and North America: 5-8 working days. </p>
            </div>

            <div className="product-holder">
                <table>
                    <tbody>
                        <tr>
                            <td width="25%">
                                <img className="imgproduct" src="images/mug-product.png" alt="Mug product" width="100%"></img>
                            </td>
                            <td width="25%">
                                <img className="imgproduct" src="images/mug-product-livingroom.png" alt="Mug product in living room" width="100%"></img>
                            </td>
                            <td className="product-details" width="50%">
                                <h3 className="product-title">White Ceramic Mug</h3>
                                <p className="product-description">This beautiful ceramic mug is a perfect reminder of your sport achievement at any time of the day: your morning coffee, a hot chocolate, or any other hot beverage you enjoy. The mug is glossy white and the details of your achievement come out beautifully and vividly on it. The print retains its quality and luster when used in both microwaves and the dishwasher. </p>
                                <table className="detail-table">
                                    <tbody>
                                        <tr className="detail-row">
                                            <td width="50%">Price</td>
                                            <td width="50%" text-align="right;"><strong>30 EUR</strong></td> 
                                        </tr>
                                        <tr className="detail-row">
                                            <td width="50%">Material</td>
                                            <td width="50%" text-align="right"><strong>Ceramic</strong></td> 
                                        </tr>
                                        <tr className="detail-row">
                                            <td width="50%">Size</td>
                                            <td width="50%" text-align="right;"><strong>325ml | 11oz</strong></td> 
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            <div/>
            </div>


            <div className="product-holder" >
                <table>
                    <tbody>
                        <tr>
                            <td width="25%">
                                <img className="imgproduct" src="images/a4-poster.png" alt="Poster" width="100%"></img>
                            </td>
                            <td width="25%">
                                <img className="imgproduct" src="images/a4-poster-wall.png" alt="Poster on the wall" width="100%"></img>
                            </td>
                            <td className="product-details" width="50%">
                                <h3 className="product-title">Paper Poster</h3>
                                <p className="product-description">This museum-quality poster is printed on long-lasting matte (uncoated) archival paper. Perfect to display on office desks and bookshelf. Framed options coming soon! </p>
                                <table className="detail-table">
                                    <tbody>
                                        <tr className="detail-row">
                                            <td width="50%">Price</td>
                                            <td width="50%" text-align="right;"><strong>25 EUR</strong></td> 
                                        </tr>
                                        <tr className="detail-row">
                                            <td width="50%">Material</td>
                                            <td width="50%" text-align="right"><strong>Matte paper 250 gsm </strong></td> 
                                        </tr>
                                        <tr className="detail-row">
                                            <td width="50%">Size</td>
                                            <td width="50%" text-align="right;"><strong>A4 | 21x29.7cm | 8x12"</strong></td> 
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            <div/>
            </div>

            <div className="blank"></div>

            <div className="footer">
                <p className="footer-left-side">Copyright © 2021 MJonline (695014). All rights reserved.  
                    <a href="/policy">Terms and conditions</a>
                </p>
            </div>
        </div>
    );
}

export default Products;