import React, { Component } from "react";
import Map from "./Map.js";

class Poster extends Component {
  render() {
    const showValues = this.props.activity;
    return (
      <div>
        <div id="divToPrint" className="mt4">
          {/* <div>Note: Here the dimensions of div are same as A4</div>
                <div>You Can add any component here</div> */}
          <Map 
            activity={this.props.activity} 
            theme={this.props.theme} 
            layout={this.props.layout} 
            allValues={this.props.allValues}
          ></Map>
          <div 
            className={
              this.props.layout === "PS" 
              ? "image-data-wrapper ps-wrapper" 
              : "image-data-wrapper"}>
            <img
              id="ImagePoster"
              src={this.props.picture ? this.props.picture : "/images/placeholder.png"}
              className={this.props.layout === "MS" ? "display-none" : "display-block"}
              alt="picture"
              style={
                this.props.layout === "PS"
                  ? { width: "100%", marginBottom: "20px", minHeight: "600px", maxHeight: "655px" }
                  : { width: "50%", height: "210px" }
              }
            />
            {showValues && (
              <div
                className="data"
                style={
                  this.props.layout === "PS" || this.props.layout === "MS" 
                  ? { width: "100%", marginBottom: "-5px" } 
                  : { width: "50%" }}
              >
                <div className={this.props.layout === "PS" || this.props.layout === "MS" ? "column-left column-left-ps" : "column-left"}>
                  <div>
                    <span className="label">{this.props.allValues.label1}</span> <span>{this.props.allValues.value1}</span>{" "}
                  </div>
                  <div>
                    <span className="label">{this.props.allValues.label2}</span> <span>{this.props.allValues.value2}</span>
                  </div>
                </div>
                <div className={this.props.layout === "PS" || this.props.layout === "MS" ? "column-right column-right-ps" : "column-right"}>
                  <div>
                    <span className="label">{this.props.allValues.label3}</span> <span>{this.props.allValues.value3}</span>
                  </div>
                  <div>
                    <span className="label">{this.props.allValues.label4}</span> <span>{this.props.allValues.value4}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={
              this.props.layout === "PS" || this.props.layout === "MS"
                ? "poster-title poster-title-ps"
                : "poster-title"
            }
          >
            {this.props.allValues.name}
          </div>
        </div>
      </div>
    );
  }

}

export default Poster;
